$bg-color: #eaeaea;
$text-color: #444;
$border-radius: .75rem;
$gradient-color1: #b1b1b1;
$gradient-color2: #fff;
$min-width: 360px;

body {
  background: $bg-color;
  color: $text-color;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app {
  display: flex;
  flex-direction: column;
  min-width: $min-width;
  @media screen and (max-width: 467px) {
    min-width: 90%;
  }

  & > * {
    margin: .5rem;
  }

  &_display {
    font-size: 1.25rem;
    border-radius: $border-radius;
    background: black;
    padding: 1rem;
    position: relative;
    z-index: 100;
    margin-bottom: 1rem;
    cursor: text;
    display: flex;

    &::after, &::before {
      content: '';
      position: absolute;
      border-radius: inherit;
    }

    &::after {
      width: calc(100%);
      height: calc(100%);
      top: 0;
      left: 0;
      background: $bg-color;
      box-shadow: 0 0 .5rem $bg-color;
      z-index: -50;
    }

    &::before {
      $size: 20px;
      width: calc(100% + #{$size});
      height: calc(100% + #{$size});
      top: #{($size / 2)* -1};
      left: #{($size / 2)* -1};
      background: linear-gradient(100deg, $gradient-color1, $gradient-color2);
      z-index: -100;
    }

    i {
      padding: 0 1rem;
    }

    input {
      font-size: 1rem;
      border: none;
      background: transparent;
      outline: none;
      width: 100%;
    }
  }

  &_block {
    display: flex;
    justify-content: center;
    font-size: 1.25rem;
  }

  &_button {
    $size: 100px;
    font-size: 1.5rem;
    margin: 2rem auto;
    width: $size;
    height: $size;
    border: none;
    outline: none;
    border-radius: 100%;
    cursor: pointer;
    position: relative;
    transition: all .3s;

    &::after {
      content: '';
      position: absolute;
      z-index: -100;
      width: inherit;
      height: inherit;
      border-radius: inherit;
      top: 0;
      left: 0;
      box-shadow: 5px 5px 10px $gradient-color1;
      transition: opacity .3s;
    }

    &:hover {
      background: white;
      color: $gradient-color1;
      box-shadow: inset 5px 5px 10px $gradient-color1;

      &::after {
        opacity: 0;
      }
    }
  }

  &_switcher {
    user-select: none;
    display: flex;
    align-items: center;
    $switcher-size: 20px;
    $switcher-border: 2px;
    $switcher-border-radius: $switcher-size / 2;
    $switcher-dot-size: 16px;

    &-input {
      display: none;

      &:checked {
        & + label {
          &::before {
            background-color: #0075FF;
          }

          &::after {
            background: $bg-color;
            left: calc(#{$switcher-size * 2} - #{$switcher-dot-size} - #{$switcher-border});
          }
        }
      }
    }

    &-label {
      position: relative;
      padding-left: calc(#{$switcher-size * 2} + .5rem);

      &::before, &::after {
        content: '';
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
      }

      &::before {
        width: $switcher-size * 2;
        height: $switcher-size;
        background-color: white;
        border-radius: $switcher-border-radius;
        border: 2px solid black;
        box-sizing: border-box;
        transition: background-color .2s;
      }

      &::after {
        width: $switcher-dot-size;
        height: $switcher-dot-size;
        border-radius: 100%;
        transition: left .2s;
        top: calc((#{$switcher-size} - #{$switcher-dot-size}) / 2);
        left: $switcher-border;
        box-shadow: 1px 1px 2px $text-color;
      }

    }
  }

  &_range {
    cursor: pointer;
  }
}

